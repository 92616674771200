<template>
  <!--begin first-menu-section -->
  <section class="section-grey">

    <!--begin container-->
    <div class="container">

      <!--begin row-->
      <div class="row">

        <!--begin col-md-6-->
        <div class="col-md-6">

          <h2 class="menu-section-title">Foires</h2>

          <!--begin menu-wrapper-->
          <div class="menu-wrapper">

            <!--begin menu-image-->
            <div class="menu-image">

              <img src="../assets/images/m13.jpeg" class="width-100" alt="food">

            </div>
            <!--end menu-image-->

            <!--begin menu-description-->
            <div class="menu-description">

              <!--begin menu-list-->
              <div class="menu-list">

                <h5>Foire du BURKIN'Daaga acte 5</h5>

              </div>
              <!--end menu-list-->

              <p class="menu-ingredients">
                Cette édition s'est déroulée au FESPACO sous le thème "Crise économique et alimentairee:défis
                et opportunités pour une adhésion populaire au consommons local
              </p>

              <div class="menu-list">
                <p class="price">21 au 27 Dec 2022</p>
                <span class="menu-dot-line"></span>

              </div>

            </div>
            <!--end menu-description-->

          </div>
          <!--end menu-wrapper-->

          <!--begin menu-wrapper-->
          <div class="menu-wrapper">

            <!--begin menu-image-->
            <div class="menu-image">

              <img src="../assets/images/m131.jpg" class="width-100" alt="food">

            </div>
            <!--end menu-image-->

            <!--begin menu-description-->
            <div class="menu-description">

              <!--begin menu-list-->
              <div class="menu-list">

                <h5>Foire du BURKIN'Daaga acte 4</h5>

              </div>
              <!--end menu-list-->

              <p class="menu-ingredients">Sous le thème "Productions locales et panorama de financements: quelles 
                stratégies pour accélérer la production et la consommation de masse 
                dans un contexte de Covid-19", cette édition s'est tenue à l'espace en face de la 
                cathédrale Ex Camp Fonctionnaire </p>

              <div class="menu-list">
                <p class="price">26 Octobre au 1er Nov 2021</p>
                <span class="menu-dot-line"></span>

              </div>

            </div>
            <!--end menu-description-->

          </div>
          <!--end menu-wrapper-->

          <!--begin menu-wrapper-->
          <div class="menu-wrapper">

            <!--begin menu-image-->
            <div class="menu-image">

              <img src="../assets/images/m13.jpeg" class="width-100" alt="food">

            </div>
            <!--end menu-image-->

            <!--begin menu-description-->
            <div class="menu-description">

              <!--begin menu-list-->
              <div class="menu-list">

                <h5>Foire du BURKIN'Daaga acte 3</h5>

              </div>
              <!--end menu-list-->

              <p class="menu-ingredients">Curabitur quas nets lacus ets nulat iaculis loremis etimus nisle varius vitae seditum fugiatum ligula aliquam quist sequi elit rutrum eleif arcu quias etis nisle varius.</p>

              <div class="menu-list">
                <p class="price">25 Dec 2022</p>
                <span class="menu-dot-line"></span>

              </div>

            </div>
            <!--end menu-description-->

          </div>
          <!--end menu-wrapper-->
        </div>
        <!--end col-md-6-->

        <!--begin col-md-6-->
        <div class="col-md-6">

          <h2 class="menu-section-title">Divers</h2>

          <!--begin menu-wrapper-->
          <div class="menu-wrapper">

            <!--begin menu-image-->
            <div class="menu-image">

              <img src="../assets/images/m121.jpg" class="width-100" alt="food">

            </div>
            <!--end menu-image-->

            <!--begin menu-description-->
            <div class="menu-description">

              <!--begin menu-list-->
              <div class="menu-list">

                <h5>Salon International des affaires au Brésil </h5>

              </div>
              <!--end menu-list-->

              <p class="menu-ingredients"> Avec pour objectif la promotion des produits
                locaux Made In Burkina, une délégation de l'association Burkina Wa-mêdô 
                conduite par son président Ibrahim Akim OUEDRAOGO a participé à la deuxième
                édition du Salon International des affaires au Brésil (FINBRASIL)</p>

              <div class="menu-list">
                <p class="price">27 au 29 Mars 2023</p>
                <span class="menu-dot-line"></span>

              </div>

            </div>
            <!--end menu-description-->

          </div>
          <!--end menu-wrapper-->
          

          <!--begin menu-wrapper-->
          <div class="menu-wrapper">

            <!--begin menu-image-->
            <div class="menu-image">

              <img src="../assets/images/m12.jpg" class="width-100" alt="food">

            </div>
            <!--end menu-image-->

            <!--begin menu-description-->
            <div class="menu-description">

              <!--begin menu-list-->
              <div class="menu-list">

                <h5>Formation Bantaaré</h5>

              </div>
              <!--end menu-list-->

              <p class="menu-ingredients">Avec pour objectif la réduction des délestages, l'augmentation de 
                l'éfficacité énergétique des appareils de froid, la meilleure conservation des produits 
                alimentaires, la formation Bantaaré a été organisée en collaboration avec New Cold Système et s'est tenue 
                à Ouagadougou au Conseil Burkinabé des Chargeurs(CBC) </p>

              <div class="menu-list">
                <p class="price">14 Jan 2023</p>
                <span class="menu-dot-line"></span>

              </div>

            </div>
            <!--end menu-description-->

          </div>
          <!--end menu-wrapper-->


          <!--begin menu-wrapper-->
          <div class="menu-wrapper">

            <!--begin menu-image-->
            <div class="menu-image">

              <img src="../assets/images/m12.jpg" class="width-100" alt="food">

            </div>
            <!--end menu-image-->

            <!--begin menu-description-->
            <div class="menu-description">

              <!--begin menu-list-->
              <div class="menu-list">

                <h5>Formation</h5>

              </div>
              <!--end menu-list-->

              <p class="menu-ingredients">Curabitur quas nets lacus ets nulat iaculis loremis etimus nisle varius vitae seditum fugiatum ligula aliquam quist sequi elit rutrum eleif arcu quias etis nisle varius.</p>

              <div class="menu-list">
                <p class="price">25 Dec 2022</p>
                <span class="menu-dot-line"></span>

              </div>

            </div>
            <!--end menu-description-->

          </div>
          <!--end menu-wrapper-->

        </div>
        <!--end col-md-6-->

      </div>
      <!--end row-->

    </div>

    <!--end container-->

  </section>
  <!--end first-menu-section -->
</template>

<script>
export default {
name: "ActivitiesSectionComponent",

}
</script>

<style scoped>

</style>