import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ActivitiesView from "@/views/ActivitiesView";
import EventsView from "@/views/EventsView";
import ContactView from "@/views/ContactView";
import GaleryView from "@/views/GaleryView";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/activites',
    name: 'activities',
    component: ActivitiesView
  },
  {
    path: '/evenements',
    name: 'events',
    component: EventsView
  },
  {
    path: '/galerie',
    name: 'galery',
    component: GaleryView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  }
]

const router = createRouter({
  history:createWebHashHistory(),
  // createWebHistory(process.env.BASE_URL),
  routes
})

export default router
