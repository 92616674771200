<template>
  <!--begin persons presentation -->
  <section class="section-white no-padding-bottom">

    <!--begin container-->
    <div class="container">

      <!--begin row-->
      <div class="row">

        <!--begin col-md-12-->
        <div class="col-md-12 text-center">

          <div class="text-center">

            <span class="comic-text">Une</span>

            <h2 class="section-title no-margin">équipe Engagée</h2>

          </div>

        </div>
        <!--end col-md-12-->

      </div>
      <!--end row-->

      <!--begin row-->
      <div class="row">

        <!--begin col-md-12-->
        <div class="col-md-12 text-center">

          <!--begin rev_slider_108_1_wrapper-->
          <div id="rev_slider_108_1_wrapper" class="rev_slider_wrapper fullwidthbanner-container"
               data-alias="food-carousel80" style="margin:0px auto;padding:0px;margin-top:0px;margin-bottom:0px;">

            <!-- START REVOLUTION SLIDER 5.0.7 fullwidth mode -->
            <div id="rev_slider_108_1" class="rev_slider fullwidthabanner" style="display:none;" data-version="5.0.7">

              <ul>

                <!-- SLIDE  -->
                <li data-index="rs-326" data-transition="fade" data-slotamount="7" data-easein="default"
                    data-easeout="default" data-masterspeed="300" data-thumb="../assets/images/rs-images/food0-150x100.jpg"
                    data-rotate="0" data-saveperformance="off" :data-title="persons[0].nom" data-description="">
                  <!-- MAIN IMAGE -->
                  <img src="../assets/images/persons/per1.jpeg" alt="" data-bgposition="center center" data-bgfit="contain"
                       data-bgrepeat="no-repeat" class="rev-slidebg" data-no-retina>
                  <!-- LAYERS -->

                  <!-- LAYER NR. 1 -->
                  <div class="tp-caption FoodCarousel-Content   tp-resizeme"
                       id="slide-326-layer-3"
                       data-x="center" data-hoffset=""
                       data-y="center" data-voffset=""
                       data-width="['420']"
                       data-height="['420']"
                       data-transform_idle="o:1;"

                       data-transform_in="z:0;rX:0;rY:0;rZ:0;sX:0.9;sY:0.9;skX:0;skY:0;opacity:0;s:800;e:Power3.easeInOut;"
                       data-transform_out="auto:auto;s:500;"
                       data-start="bytrigger"
                       data-splitin="none"
                       data-splitout="none"
                       data-responsive_offset="on"

                       data-end="bytrigger"
                       data-lasttriggerstate="reset"
                       style="z-index: 5; min-width: 420px; max-width: 420px; max-width: 420px; max-width: 420px; white-space: normal; border: 2px solid black;">
                    <span class="foodcarousel-headline"
                          style=" color: #cf564b; font-weight:700;">{{ persons[0].nom }}</span><br/>
                    {{ persons[0].role }}
                    <hr style="border-top: 1px solid #292e31;">
                    {{ persons[0].parcourt }}
                  </div>

                  <!-- LAYER NR. 2 -->
                  <div class="tp-caption FoodCarousel-Button rev-btn "
                       id="slide-326-layer-1"
                       data-x="center" data-hoffset=""
                       data-y="bottom" data-voffset="50"
                       data-width="['auto']"
                       data-height="['auto']"
                       data-transform_idle="o:1;"
                       data-transform_hover="o:1;rX:0;rY:0;rZ:0;z:0;s:300;e:Power1.easeInOut;"
                       data-style_hover="c:rgba(255, 255, 255, 1.00);bg:rgba(41, 46, 49, 1.00);cursor:pointer;"

                       data-transform_in="opacity:0;s:300;e:Power3.easeInOut;"
                       data-transform_out="opacity:0;s:300;s:300;"
                       data-start="0"
                       data-splitin="none"
                       data-splitout="none"
                       data-actions='[{"event":"click","action":"startlayer","layer":"slide-326-layer-3","delay":""},{"event":"click","action":"startlayer","layer":"slide-326-layer-5","delay":"200"},{"event":"click","action":"stoplayer","layer":"slide-326-layer-1","delay":""}]'
                       data-responsive_offset="on"
                       data-responsive="off"
                       data-lasttriggerstate="reset"
                       style="z-index: 6; white-space: nowrap;outline:none;box-shadow:none;box-sizing:border-box;-moz-box-sizing:border-box;-webkit-box-sizing:border-box; border: 2px solid black;">
                    <i class="pe-7s-note2" style="font-size: 21px; float: left;margin-top:-6px;margin-right:10px;"></i>
                    A Propos de {{ persons[0].nom }}
                  </div>

                  <!-- LAYER NR. 3 -->
                  <div class="tp-caption FoodCarousel-CloseButton rev-btn  tp-resizeme"
                       id="slide-326-layer-5"
                       data-x="441"
                       data-y="110"
                       data-width="['auto']"
                       data-height="['auto']"
                       data-transform_idle="o:1;"
                       data-transform_hover="o:1;rX:0;rY:0;rZ:0;z:0;s:300;e:Power1.easeInOut;"
                       data-style_hover="c:rgba(255, 255, 255, 1.00);bg:rgba(41, 46, 49, 1.00);cursor:pointer;"

                       data-transform_in="z:0;rX:0;rY:0;rZ:0;sX:0.9;sY:0.9;skX:0;skY:0;opacity:0;s:800;e:Power3.easeInOut;"
                       data-transform_out="auto:auto;s:500;"
                       data-start="bytrigger"
                       data-splitin="none"
                       data-splitout="none"
                       data-actions='[{"event":"click","action":"stoplayer","layer":"slide-326-layer-3","delay":""},{"event":"click","action":"stoplayer","layer":"slide-326-layer-5","delay":""},{"event":"click","action":"startlayer","layer":"slide-326-layer-1","delay":""}]'
                       data-responsive_offset="on"

                       data-end="bytrigger"
                       data-lasttriggerstate="reset"
                       style="z-index: 7; white-space: nowrap;outline:none;box-shadow:none;box-sizing:border-box;-moz-box-sizing:border-box;-webkit-box-sizing:border-box;">
                    <i class="fa fa-remove"></i>
                  </div>

                </li>

              </ul>

              <div class="tp-bannertimer tp-bottom" style="visibility: hidden !important;"></div>

            </div>

          </div>
          <!--end rev_slider_108_1_wrapper-->

        </div>
        <!--end col-md-12-->

      </div>
      <!--end row-->

    </div>
    <!--end container-->

  </section>
  <!--end persons presentation -->
</template>

<script>
export default {
  name: "MembersPresentationSectionComponent",

  data() {
    return {
      persons: [
        {
          nom: "Ibrahim Akim OUEDRAOGO",
          image:"../assets/images/persons/per1.jpeg",
          role: `Président de Burkina Wa-mêdô.`,
          parcourt: `Décidé à apporter depuis fort longtemps sa contribution au développemnt du Burkina Faso, 
          Ibrahim Akim OUEDRAOGO lance en 2017 Burkina Wa-mêdô dont il est le president actuellement`,
        },
       
      ]

    }
  },
}
</script>

<style scoped>

</style>