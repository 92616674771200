<template>
  <!--begin festival-section -->
  <div class="image-section">

    <!--begin image-overlay -->
    <div class="image-overlay"></div>
    <!--end image-overlay -->

    <!--begin container-->
    <div class="container image-section-inside">

      <!--begin row-->
      <div class="row">

        <!--begin col-md-10-->
        <div class="col-md-10 col-md-offset-1 text-center">

          <p class="hero-text2 white wow bounceIn">"La foire BURKIN'Daaga première, du genre au Burkina 
            Faso est une foire organisée par l'association BURKINA WA-MEDO en partenariat avec la 
            chambre de commerce et d'industrie du Burkina dédiée uniquement à la prommotion et à la 
            consommation des produits made in BURKINA FASO
           </p>

        </div>
        <!--end col-md-6-->

      </div>
      <!--end row-->

    </div>
    <!--end container-->

  </div>
  <!--end festival-section -->
</template>

<script>
export default {
name: "FestivalSectionComponent"
}
</script>

<style scoped>

</style>