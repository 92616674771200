<template>
  <!-- SLIDE  -->
  <li data-index="rs-122" data-transition="fade" data-slotamount="7" data-easein="default" data-easeout="default"
      data-masterspeed="300" data-rotate="0" data-saveperformance="off" data-title="Real Webdesign"
      data-param1="Regular Image" data-description="">

    <!-- MAIN IMAGE -->
    <img src="../assets/images/rs3.jpeg" 
         alt="" data-bgposition="center center" 
         data-bgfit="cover" data-bgrepeat="no-repeat"
         class="rev-slidebg" data-no-retina>

  </li>
  
  <li data-index="rs-122" data-transition="fade" data-slotamount="7" data-easein="default" data-easeout="default"
  data-masterspeed="300" data-rotate="0" data-saveperformance="off" data-title="Real Webdesign"
  data-param1="Regular Image" data-description="">

  <img src="../assets/images/rs2.jpeg"
       alt="" data-bgposition="center center"
       data-bgfit="cover" data-bgrepeat="no-repeat"
       class="rev-slidebg" data-no-retina>

  </li>
</template>

<script>
export default {
name: "ImageSlide"
}
</script>

<style scoped>

</style>