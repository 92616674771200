<template>
  <!--begin presentation-->
  <section id="about" class="section-white">
    <!--begin container-->
    <div class="container">

      <!--begin row-->
      <div class="row">

        <!--begin col-md-6-->
        <div class="col-md-6 text-center padding-top-50 padding-bottom-50 wow slideInLeft">

          <span class="comic-text">Une</span>

          <h2 class="section-title">Association</h2>

          <p>à but non lucrative regroupant plus de 250 organisations associatives ainsi que des personnes physiques et morales,
            sans distinction de sexe, de race ou de religion, travaillant dans la production et la transformation des
            produits Burkinabè.
          </p>

          <a href="https://burkinawamedo.shop" class="btn btn-lg btn-yellow">VISITER NOTRE BOUTIQUE</a>

        </div>
        <!--end col-md-6-->

        <!--begin col-md-6-->
        <div class="col-md-6 wow slideInRight">

          <img src="../assets/images/a1.jpg" alt="picture" class="width-100">

        </div>
        <!--end col-md-6-->

      </div>
      <!--end row-->

    </div>
    <!--end container-->

  </section>
  <!--end presentation-->
</template>

<script>

export default {
  name: "PresentationComponent",
}
</script>

<style scoped>

</style>