<template>
  <!--begin section-grey-->
  <section class="section-grey">

    <!--begin container-->
    <div class="container margin-top-60">

      <!--begin row-->
      <div class="row">

        <!--begin col-md-10-->
        <div class="col-md-10 col-md-offset-1 text-center">

          <h3 class="section-title small-margin wow fadeIn" data-wow-delay="0.5s">CONTACTEZ-NOUS</h3>

          <p class="wow fadeInUp" data-wow-delay="1s">Laissez nous un message pour d'eventuelles suggestion et besoins<br>
           </p>

          <!--begin contact-wrapper-->
          <div class="contact-wrapper">

            <!--begin success message -->
            <p class="contact_success_box" style="display:none;">Nous avons bien reçu votre message et vous aurez bientôt de nos nouvelles.
              On vous remercie !</p>
            <!--end success message -->

            <!--begin contact form -->
            <form id="contact-form" class="contact"
                  action="#" method="post">

              <!--begin col-md-6-->
              <div class="col-md-6 wow slideInLeft" data-wow-delay="0.5s">
                <input class="contact-input white-input" required name="contact_names" placeholder="Nom Complet*"
                       type="text">
                <input class="contact-input white-input" required name="contact_email" placeholder="Adresse Email*"
                       type="email">
                <input class="contact-input white-input" required name="contact_phone" placeholder="Numéro de Téléphone*"
                       type="text">
              </div>
              <!--end col-md-6-->

              <!--begin col-md-6-->
              <div class="col-md-6 wow slideInRight" data-wow-delay="0.5s">
                <textarea class="contact-commnent white-input" rows="2" cols="20" name="contact_message"
                          placeholder="Votre Message..."></textarea>
              </div>
              <!--end col-md-6-->

              <input value="Envoyer" id="submit-button" class="contact-submit wow fadeInUp" data-wow-delay="1s"
                     type="submit">

            </form>
            <!--end contact form -->

          </div>
          <!--end contact-wrapper-->

        </div>
        <!--end col-md-10-->

      </div>
      <!--end row-->

    </div>
    <!--end container-->

  </section>
  <!--end section-grey-->
</template>

<script>
export default {
  name: "ContactForm"
}
</script>

<style scoped>

</style>