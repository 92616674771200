<template>
  <!--begin section-white-->
  <section id="events" class="section-white small-padding-bottom">

    <!--begin container-->
    <div class="container">

      <news-title-component/>

      <!--begin row-->
      <div class="row ">
        <div class="col-6"></div>
          <event-card-component :titre="titre1" :description="description1" :image-link="imageLink"/>
      </div>
      <!--end row-->

      <!--begin row-->

      <!--end row-->

    </div>
    <!--end container-->

  </section>
  <!--end section-white-->
</template>

<script>
import NewsTitleComponent from "@/components/NewsTitleComponent";
import EventCardComponent from "@/components/EventCardComponent";
export default {
  name: "NewsSection",
  components: {EventCardComponent, NewsTitleComponent},
  data(){
    return {
      titre1: "BURKIN'Daaga Acte 6",
      description1: "<< Consommons ceux dont nous produisons et produisons ceux dont nous consommons>> disait Thomas Sankara Waouh ! Consommateurs, producteurs et promoteurs des produits locaux au Burkina,de la fin du mois de novembre au début décembre à #kaya , l'association Burkina-Wamêdo vous offre une vitrine de promotion de vos talents aux yeux du monde. À cette occasion, des stands seront mis à votre disposition pour l'exposition de vos produits. C'est une opportunité à ne pas rater. \n" +
          "Pour plus de détails, contactez-nous sur :70274582/78866357\n" +
          "\n" +
          "#Kaya2023 \n" +
          "#burkindaaga \n" +
          "#burkinawamedo",
      imageLink:require("../assets/images/imageEvenement/Burkindaaga-6.jpeg")
    }
  }
}
</script>

<style scoped>

</style>