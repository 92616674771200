<template>
  <!-- begin revolutions slider -->
  <div id="rev_slider_30_1_wrapper" class="rev_slider_wrapper fullwidthbanner-container margin-top-60"
       data-alias="media-carousel-autoplay30"
       style="margin:0px auto;background-color:#fff;padding:0px;margin-top:0px;margin-bottom:0px;">

    <!-- START REVOLUTION SLIDER 5.0.7 fullwidth mode -->
    <div id="rev_slider_30_1" class="rev_slider fullwidthabanner" style="display:none;" data-version="5.0.7">

      <ul>

        <image-slide/>
        <image-slide/>
        <image-slide/>
        <image-slide/>
        <image-slide/>
        <image-slide/>
        <image-slide/>
        <image-slide/>
        <image-slide/>
        <image-slide/>

      </ul>

      <div class="tp-bannertimer tp-bottom" style="visibility: hidden !important;"></div>

    </div>

  </div>
  <!-- end revolutions slider -->
</template>

<script>
import ImageSlide from "@/components/ImageSlide";
export default {
  name: "ImageScroler",
  components: {ImageSlide}
}
</script>

<style scoped>

</style>