<template>
  <news-section/>
</template>

<script>
import NewsSection from "@/components/NewsSection";
export default {
name: "EventsView",
  components: {NewsSection}
}
</script>

<style scoped>

</style>