<template>
  <div class="home">
    <home-welcome/>
    <presentation-component/>
    <festival-section-component/>
    <members-presentation-section-component/>
  </div>
</template>

<script>


import HomeWelcome from "@/components/HomeWelcome";
import PresentationComponent from "@/components/PresentationComponent";
import FestivalSectionComponent from "@/components/FestivalSectionComponent";
import MembersPresentationSectionComponent from "@/components/MembersPresentationSectionComponent";
// import FooterComponent from "@/components/FooterComponent";
export default {
  name: 'HomeView',
  components: {
    MembersPresentationSectionComponent,
    FestivalSectionComponent,
    PresentationComponent,
    HomeWelcome

  }
}
</script>
