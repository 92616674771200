<template>
  <!--begin footer -->
  <div class="footer">

    <!--begin container -->
    <div class="container">

      <!--begin row -->
      <div class="row footer-top">

        <!--begin col-md-4 -->
        <div class="col-md-4 padding-bottom-50">
          <p class="contact_info"><i class="fa fa-map-marker"></i> 01BP4178, Ouagadougou, Burkina Faso</p>
        </div>
        <!--end col-md-4 -->

        <!--begin col-md-4 -->
        <div class="col-md-4 padding-bottom-50">
          <p class="contact_info"><i class="fa fa-phone"></i> +226 25 47 23 36</p>
        </div>
        <!--end col-md-4 -->

        <!--begin col-md-4 -->
        <div class="col-md-4 padding-bottom-50">
          <p class="contact_info"><i class="fa fa-envelope-square"></i> <a href="burkinawamedo@gmail.com">contact@wa-medo.com</a>
          </p>
        </div>
        <!--end col-md-4 -->

      </div>
      <!--end row -->

      <!--begin row -->
      <div class="row">

        <!--begin footer-bottom -->
        <div class="footer-bottom">

          <!--begin col-md-5 -->
          <div class="col-md-5">

            <!--begin copyright -->
            <div class="copyright ">
              <p>© 2023 Burkina Wa-Mêdô</p>
            </div>
            <!--end copyright -->

          </div>
          <!--end col-md-5 -->

          <!--begin col-md-2 -->
          <div class="col-md-2 text-center">
            <a href="#scroll-top-pin" class="scrool top-scroll"><i class="fa fa-angle-double-up"></i></a>
          </div>
          <!--end col-md-2 -->

          <!--begin col-md-5 -->
          <div class="col-md-5">

            <!--begin footer_social -->
            <ul class="footer_social">
              <li>
                Suivez nous:
              </li>
              <li>
                <a href="#">
                  <i class="fa fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href="www.facebook.com/burkinawamedo">
                  <i class="fa fa-facebook"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i class="fa fa-instagram"></i>
                </a>
              </li>
            </ul>
            <!--end footer_social -->

          </div>
          <!--end col-md-5 -->

        </div>
        <!--end footer-bottom -->

      </div>
      <!--end row -->

    </div>
    <!--end container -->

  </div>
  <!--end footer -->
</template>

<script>
export default {
name: "FooterComponent"
}
</script>

<style scoped>

</style>