<template>
  <!--begin col-md-4 -->
  <div class="col-md-4 margin-bottom-30">

    <!--begin popup image -->
    <div class="popup-wrapper">
      <div class="image-container">
        <img :src="image" class="width-100" alt="pic">
      </div>
    </div>
    <!--begin popup image -->

  </div>
  <!--end col-md-4-->
</template>

<script>
export default {
name: "GalleryImage",
  props: {
    image: String,
  }
}
</script>

<style scoped>

</style>