<template>
  <!--begin menu-hero-section -->
  <section id="hero-section" class="menu-hero-section">

    <!--begin image-overlay -->
    <div class="image-overlay"></div>
    <!--end image-overlay -->

    <!--begin container-->
    <div class="container image-section-inside">

      <!--begin row-->
      <div class="row">

        <!--begin col-md-10-->
        <div class="col-md-10 col-md-offset-1 text-center">

          <span class="comic-text wow fadeIn" data-wow-delay="0.5s">Catalogue De</span>

          <h2 class="section-title white wow bounceIn" data-wow-delay="1s">Nos Activités</h2>

          <p class="hero-text wow fadeInUp" data-wow-delay="2s"> Trouver là les différentes actions que l'association a mené pour 
            la promotion du consommé local
            </p>

        </div>
        <!--end col-md-10-->

      </div>
      <!--end row-->

    </div>
    <!--end container-->

  </section>
  <!--end menu-hero-section -->
</template>

<script>
export default {
name: "ActivitiesHeaderComponent"
}
</script>

<style scoped>

</style>