<template>
  <!--begin borders -->
  <div id="border-left"></div>
  <div id="border-right"></div>
  <!--end borders -->

  <!--begin loader -->
  <div id="loader">
    <div class="sk-three-bounce">
      <div class="sk-child sk-bounce1"></div>
      <div class="sk-child sk-bounce2"></div>
      <div class="sk-child sk-bounce3"></div>
    </div>
  </div>
  <!--end loader -->

  <header-component />

  <router-view/>

  <footer-component/>
</template>

<style>

</style>
<script>
import HeaderComponent from "@/components/HeaderComponent";
import FooterComponent from "@/components/FooterComponent";
export default {
  components: {FooterComponent, HeaderComponent}
}
</script>