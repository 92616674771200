<template>
  <!--begin home-hero-section -->
  <div id="hero-section" class="home-hero-section">

    <!-- BEGIN REVOLUTION SLIDER -->
    <div id="rev_slider_wrapper" class="rev_slider_wrapper fullscreen-container" data-alias="restaurant-header"
         style="background-color:#222222;padding:0; min-height:600px">

      <!-- START REVOLUTION SLIDER 5.1.4 fullscreen mode -->
      <div id="rev_slider1" class="rev_slider fullscreenbanne">

        <ul>
          <!-- SLIDE  -->
          <li data-index="rs-897" data-transition="fade" data-slotamount="default" data-easein="Power4.easeInOut"
              data-easeout="default" data-masterspeed="2000" data-rotate="0" data-saveperformance="off"
              data-title="Slide" data-param1="" data-param2="" data-param3="" data-param4="" data-param5=""
              data-param6="" data-param7="" data-param8="" data-param9="" data-param10="" data-description="">

            <!-- MAIN IMAGE -->
            <img src="../assets/images/rs3.jpeg" alt="" data-bgposition="center center" data-bgfit="cover"
                 data-bgrepeat="no-repeat" data-bgparallax="3" class="rev-slidebg" data-no-retina>
            <!-- LAYERS -->

            <!-- LAYER NR. 1 -->
            <div class="tp-caption  " id="slide-897-layer-14" data-x="['center','center','center','center']"
                 data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']"
                 data-voffset="['0','0','0','0']" data-width="full-proportional" data-height="full-proportional"
                 data-whitespace="nowrap" data-transform_idle="o:1;"
                 data-transform_in="z:0;rX:0;rY:0;rZ:0;sX:0.9;sY:0.9;skX:0;skY:0;opacity:0;s:2000;e:Power3.easeInOut;"
                 data-transform_out="sX:1.1;sY:1.1;opacity:0;s:800;s:800;" data-start="bytrigger" data-basealign="slide"
                 data-responsive_offset="off" data-responsive="off" data-end="bytrigger" data-lasttriggerstate="reset"
                 style="z-index: 5;cursor:pointer;"><img src="../assets/images/rs1.jpeg" alt="" width="1920" height="1282"
                                                         data-ww="['full-proportional','full-proportional','full-proportional','full-proportional']"
                                                         data-hh="['full-proportional','1290px','1290px','1290px']"
                                                         data-no-retina>
            </div>

            <!-- LAYER NR. 2 -->
            <div class="tp-caption  " id="slide-897-layer-13" data-x="['center','center','center','center']"
                 data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']"
                 data-voffset="['0','0','0','0']" data-width="full-proportional" data-height="full-proportional"
                 data-whitespace="nowrap" data-transform_idle="o:1;"
                 data-transform_in="z:0;rX:0;rY:0;rZ:0;sX:0.9;sY:0.9;skX:0;skY:0;opacity:0;s:2000;e:Power3.easeInOut;"
                 data-transform_out="sX:1.1;sY:1.1;opacity:0;s:800;s:800;" data-start="bytrigger" data-basealign="slide"
                 data-responsive_offset="off" data-responsive="off" data-end="bytrigger" data-lasttriggerstate="reset"
                 style="z-index: 6;cursor:pointer;"><img src="../assets/images/rs2.jpeg" alt="" width="1920" height="1280"
                                                         data-ww="['full-proportional','full-proportional','full-proportional','full-proportional']"
                                                         data-hh="['full-proportional','1290px','1290px','1290px']"
                                                         data-no-retina>
            </div>

            <!-- LAYER NR. 3 -->
            <div class="tp-caption tp-shape tp-shapewrapper " id="slide-897-layer-1"
                 data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                 data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']" data-width="full"
                 data-height="full" data-whitespace="nowrap" data-transform_idle="o:1;"
                 data-transform_in="opacity:0;s:1500;e:Power3.easeInOut;" data-transform_out="opacity:0;s:500;s:500;"
                 data-start="0" data-basealign="slide" data-responsive_offset="off" data-responsive="off"
                 style="z-index: 7;background-color:rgba(34, 34, 34, 0.55);border-color:rgba(0, 0, 0, 0);">
            </div>

            <!-- LAYER NR. 4 -->
            <div class="tp-caption Dining-Title   tp-resizeme" id="slide-897-layer-7"
                 data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                 data-y="['middle','middle','middle','middle']" data-voffset="['-130','-130','-130','-130']"
                 data-width="none" data-height="none" data-whitespace="nowrap" data-transform_idle="o:1;"
                 data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;s:2000;e:Power2.easeInOut;"
                 data-transform_out="opacity:0;s:300;s:300;" data-mask_in="x:0px;y:0px;s:inherit;e:inherit;"
                 data-start="1500" data-splitin="none" data-splitout="none" data-responsive_offset="on"
                 style="z-index: 9; white-space: nowrap; font-size: 100px; line-height: 120px;text-align:center;"><img
                src="../assets/images/logo1.png" alt="" width="323" height="200" data-no-retina>
            </div>

            <!-- LAYER NR. 5 -->
            <div class="tp-caption Dining-Title   tp-resizeme" id="slide-897-layer-2"
                 data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                 data-y="['middle','middle','middle','middle']" data-voffset="['80','80','89','80']"
                 data-fontsize="['70','70','70','50']" data-lineheight="['150','150','80','60']" data-width="none"
                 data-height="none" data-whitespace="nowrap" data-transform_idle="o:1;"
                 data-transform_in="y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;s:2000;e:Power2.easeInOut;"
                 data-transform_out="opacity:0;s:300;s:300;" data-mask_in="x:0px;y:0px;s:inherit;e:inherit;"
                 data-start="1750" data-splitin="none" data-splitout="none" data-responsive_offset="on"
                 style="z-index: 10; white-space: nowrap;color:#fff; font-weight:700;font-family: 'Montserrat', sans-serif;">
              {{ title }}
            </div>

            <!-- LAYER NR. 6 -->
            <div class="tp-caption Dining-SubTitle   tp-resizeme" id="slide-897-layer-4"
                 data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                 data-y="['middle','middle','middle','middle']" data-voffset="['150','150','150','150']"
                 data-fontsize="['24','22','22','22']" data-lineheight="['28','28','38','40']"
                 data-width="['none','none','480','360']" data-height="none"
                 data-whitespace="['nowrap','nowrap','normal','normal']" data-transform_idle="o:1;"
                 data-transform_in="y:50px;opacity:0;s:2000;e:Power2.easeInOut;"
                 data-transform_out="opacity:0;s:300;s:300;" data-start="2000" data-splitin="none" data-splitout="none"
                 data-responsive_offset="on"
                 style="z-index: 11; white-space: nowrap; color: rgba(255, 255, 255,1);text-align:center;font-family: 'Open Sans', sans-serif; font-size:20px; font-weight:400;">
              {{ subTitle }} <br>
            </div>
          <div class="store-text-and-about-btn-container">
            <!-- LAYER NR. 7 -->
            <div class="tp-caption Dining-BtnLight rev-btn  rs-parallaxlevel-2" id="slide-897-layer-8"
                 data-x="['center','center','center','center']" data-hoffset="['-100','-100','0','0']"
                 data-y="['middle','middle','middle','middle']" data-voffset="['170','170','170','170']"
                 data-width="none" data-height="none" data-whitespace="nowrap" data-transform_idle="o:1;"
                 data-transform_hover="o:1;rX:0;rY:0;rZ:0;z:0;s:200;e:Power1.easeInOut;"
                 data-style_hover="c:rgba(255, 255, 255, 1.00);bc:rgba(255, 255, 255, 1.00);"
                 data-transform_in="y:50px;opacity:0;s:2000;e:Power2.easeInOut;"
                 data-transform_out="opacity:0;s:300;s:300;" data-start="2250" data-splitin="none" data-splitout="none"
                 data-actions='[{"event":"click","action":"scrollbelow","offset":"px"},{"event":"mouseenter","action":"startlayer","layer":"slide-897-layer-14","delay":""},{"event":"mouseleave","action":"stoplayer","layer":"slide-897-layer-14","delay":""}]'
                 data-responsive_offset="off" data-responsive="off"
                 style="z-index: 12;
                  white-space: nowrap;
                  outline:none;
                  box-shadow:none;
                  box-sizing:border-box;
                  -moz-box-sizing:border-box;
                  -webkit-box-sizing:border-box;
                  cursor:pointer;
                  border: 2px solid white;
                  margin-top: 50px;
                  ">
              <a href="#" style="color:#fff; font-family: 'Open Sans', sans-serif; font-weight:700;">{{  aboutBtnText }}</a>
            </div>

            <!-- LAYER NR. 8 -->
            <div class="tp-caption Dining-BtnLight rev-btn  rs-parallaxlevel-2" id="slide-897-layer-9"
                 data-x="['center','center','center','center']" data-hoffset="['100','100','0','0']"
                 data-y="['middle','middle','middle','middle']" data-voffset="['170','170','230','230']"
                 data-width="none" data-height="none" data-whitespace="nowrap" data-transform_idle="o:1;"
                 data-transform_hover="o:1;rX:0;rY:0;rZ:0;z:0;s:200;e:Power1.easeInOut;"
                 data-style_hover="c:rgba(255, 255, 255, 1.00);bc:rgba(255, 255, 255, 1.00);"
                 data-transform_in="y:50px;opacity:0;s:2000;e:Power2.easeInOut;"
                 data-transform_out="opacity:0;s:300;s:300;" data-start="2500" data-splitin="none" data-splitout="none"
                 data-actions='[{"event":"click","action":"scrollbelow","offset":"px"},{"event":"mouseenter","action":"startlayer","layer":"slide-897-layer-13","delay":""},{"event":"mouseleave","action":"stoplayer","layer":"slide-897-layer-13","delay":""}]'
                 data-responsive_offset="off" data-responsive="off"
                 style="z-index: 13;
                  white-space: nowrap;
                  outline:none;box-shadow:none;
                  box-sizing:border-box;
                  -moz-box-sizing:border-box;
                  -webkit-box-sizing:border-box;
                  cursor:pointer; 
                  border: 2px solid white;
                  margin-top: 50px;">

              <a href="https://burkinawamedo.shop" style="color:#fff; font-family: 'Open Sans', sans-serif; font-weight:700;">{{ storeText }}</a>

            </div>
          </div>
          
          </li>

        </ul>

        <div class="tp-bannertimer tp-bottom" style="visibility: hidden !important;"></div>

      </div>

    </div>
    <!-- END REVOLUTION SLIDER -->

  </div>
  <!--end home-hero-section -->


</template>

<script>
  export default {
    name: "HomeWelcome",

    data() {
      return {
        title: "WA-MEDO",
        subTitle: '"Consommons burkinabè !"',
        aboutBtnText: "PARCOURIR",
        storeText: "PRODUITS"
      }
    },
}
</script>

<style scoped>

</style>