<template>
  <image-scroler/>
  <galery-grid-component/>
</template>

<script>
import ImageScroler from "@/components/ImageScroler";
import GaleryGridComponent from "@/components/GaleryGridComponent";
export default {
  name: "GaleryView",
  components: {GaleryGridComponent, ImageScroler}
}
</script>

<style scoped>

</style>