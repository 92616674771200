<template>
  <!--begin header -->
  <header class="header" id="scroll-top-pin">

    <!--begin nav -->
    <nav class="navbar navbar-default navbar-fixed-top">

      <!--begin container -->
      <div class="container">

        <!--begin navbar -->
        <div class="navbar-header">
          <button data-target="#navbar-collapse-02" data-toggle="collapse" class="navbar-toggle" type="button">
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>

          <a href="#" class="navbar-brand brand scrool mt-0">
            <img src="../assets/images/logo.png" alt="logo" height="30">
          </a>
        </div>

        <div id="navbar-collapse-02" class="collapse navbar-collapse">
          <ul class="nav navbar-nav navbar-right">
            <li :class="{ selected: isHome}" @click="reloadPage('/')"><router-link to="/"><a href="#">Accueil</a></router-link></li>
            <li :class="{ selected: isActivities}" @click="reloadPage('/activites')"><router-link to="/activites"><a href="#">Nos activités</a></router-link></li>
            <li :class="{ selected: isEvents}" @click="reloadPage('/evenements')"><router-link to="/evenements"><a href="#">événements</a></router-link></li>
            <li :class="{ selected: isGallery}" @click="reloadPage('/galerie')"><router-link to="/galerie"><a href="#">Gallerie</a></router-link></li>
            <li><a href="https://burkinawamedo.shop">Nos produits</a></li>
            <li :class="{ selected: isContact}" @click="reloadPage('/contact')"><router-link to="/contact"><a href="#">Nous Contacter</a></router-link></li>
          </ul>
        </div>
        <!--end navbar -->

      </div>
      <!--end container -->

    </nav>
    <!--end nav -->

  </header>
  <!--end header -->
</template>

<script>
export default {
name: "HeaderComponent",

  data: function () {
    return {
      isHome: true,
      isActivities: false,
      isEvents: false,
      isGallery: false,
      isContact: false,
    }
  },

  mounted() {
    if (localStorage.currentpath) {
      this.activateCurrentLink(localStorage.currentpath);
    }
  },

  methods: {
    reloadPage(path) {
      this.$router.push(path);
      localStorage.currentpath = path;
      window.location.reload();
    },
    activateCurrentLink(path){
      switch (path){
        case "/":
          this.isHome = true;
          this.isActivities = false;
          this.isEvents = false;
          this.isGallery = false;
          this.isContact = false;
          break;
        case "/activites":
          this.isHome = false;
          this.isActivities = true;
          this.isEvents = false;
          this.isGallery = false;
          this.isContact = false;
          break;
        case "/evenements":
          this.isHome = false;
          this.isActivities = false;
          this.isEvents = true;
          this.isGallery = false;
          this.isContact = false;
          break;
        case "/galerie":
          this.isHome = false;
          this.isActivities = false;
          this.isEvents = false;
          this.isGallery = true;
          this.isContact = false;
          break;
        case "/contact":
          this.isHome = false;
          this.isActivities = false;
          this.isEvents = false;
          this.isGallery = false;
          this.isContact = true;
          break;
      }
    }
  }
}
</script>

<style scoped>

</style>