<template>
  <activities-header-component/>
  <activities-section-component/>
</template>

<script>
import ActivitiesSectionComponent from "@/components/ActivitiesSectionComponent";
import ActivitiesHeaderComponent from "@/components/ActivitiesHeaderComponent";
export default {
  name: "ActivitiesView",
  components: {ActivitiesHeaderComponent, ActivitiesSectionComponent}
}
</script>

<style scoped>

</style>