<template>
  <!--begin col-md-4 -->
  <div class="col-md-4 wow bounceInUp" style="margin-left: 35%">

    <!--begin blog-item -->
    <div class="blog-item ">

      <!--begin popup image -->
      <div class="popup-wrapper">
        <div class="popup-gallery">
          <img :src="imageLink" class="width-100" alt="pic"><span class="eye-wrapper">
            <i class="pe-7s-expand1 eye-icon"></i></span>
        </div>
      </div>
      <!--begin popup image -->

      <!--begin blog-item_inner -->
      <div class="blog-item-inner">

        <h3 class="blog-title"><a href="#">{{ titre }}</a></h3>


        <p>{{ description }}</p>

        <span class="btn btn-lg btn-yellow-small scrool" style="cursor: default">29 Novembre - 02 Décembre 2023</span>

      </div>
      <!--end blog-item-inner -->

    </div>
    <!--end blog-item -->

  </div>
  <!--end col-md-4-->
</template>

<script>
export default {
  name: "EventCardComponent",
 props:{
    imageLink:{
        type:String,
        required: true,
        default:require("../assets/images/rs8.jpg")
    },
   description: {
      type: String,
      required: true,
      default :"Quis autem velis et reprehender etims quiste voluptate velit esse quam nihil etsa illum sedit consequatur\n" +
"          quias voluptas sit netsum."
   },
   titre: {
     type: String,
     required: true
   }
 },

}
</script>

<style scoped>

</style>