<template>
  <contact-form/>
</template>

<script>
import ContactForm from "@/components/ContactForm";
export default {
name: "ContactView",
  components: {ContactForm}
}
</script>

<style scoped>

</style>