<template>
  <!--begin row-->
  <div class="row margin-bottom-40 margin-top-40">

    <!--begin col-md-12-->
    <div class="col-md-12 text-center">

      <div class="text-center">

        <span class="comic-text wow fadeIn">Spécial</span>

        <h2 class="section-title wow bounceIn">événements à Venir</h2>

      </div>

    </div>
    <!--end col-md-12-->

  </div>
  <!--end row-->
</template>

<script>
export default {
name: "NewsTitleComponent"
}
</script>

<style scoped>

</style>