<template>
  <!--begin section-white-->
  <section class="section-white">

    <!--begin container-->
    <div class="container">
      <!--begin categorie container-->
      <div class="categorie-container">
        <div class="categorie-title-container">
          <h2>Burkin'Daaga</h2>
        </div>
      
        <!--begin row-->
        <div class="row">

        <gallery-image :image="imageurls[0]"/>
        <gallery-image :image="imageurls[1]"/>
        <gallery-image :image="imageurls[2]"/>

        </div>
        <!--end row-->
      </div>
      <!--end categorie container-->
      <!--begin categorie container-->
      <div class="categorie-container">
        <div class="categorie-title-container">
          <h2>Formation Bantaaré</h2>
        </div>
      
        <!--begin row-->
        <div class="row">

          <gallery-image :image="imageurls[3]"/>
          <gallery-image :image="imageurls[4]"/>
          <gallery-image :image="imageurls[5]"/>

        </div>
        <!--end row-->
      </div>
      <!--end categorie container-->

    </div>
    <!--end container-->

  </section>
  <!--end section-white-->
</template>

<script>
import GalleryImage from "@/components/GalleryImage";
export default {
  name: "GaleryGridComponent",
  components: {GalleryImage},
 data(){
return{
  imageurls:[require("../assets/images/m13.jpeg"),
            require("../assets/images/m121.jpg"),
            require("../assets/images/m131.jpg"),
            require("../assets/images/m12.jpg"),
            require("../assets/images/fb2.jpg"),
            require("../assets/images/fb1.jpg")
          ]
}
  }  

}
</script>

<style scoped>

</style>